import React from "react";

import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/templates/common/Hero";
import Feature from "../../sections/templates/pricing/Feature";
import PricingTable1 from "../../sections/templates/pricing/PricingTable1";
import FAQ from "../../sections/templates/pricing/FAQ";

const Pricing1 = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Hero title="Pricing">
          Create custom landing pages with Omega that converts more visitors
          than any website.
        </Hero>
        <PricingTable1 />
        <Feature />
        <FAQ />
      </PageWrapper>
    </>
  );
};
export default Pricing1;
